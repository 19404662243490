import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/BasicReferences/:pTypeCode',
    name: 'browsebasicreferences',
    meta: {
      title: 'Consulta de Tabla de Referencia',
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "browsebasicreferences" */ '../views/BasicReferences/BrowseBasicReferences.vue')
  },
  {
    path: '/BasicReference/:pTypeCode/:pCode?',
    name: 'editbasicreference',
    meta: {
      title: 'Edición de Tabla de Referencia',
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "editbasicreference" */ '../views/BasicReferences/EditBasicReference.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((<any>window).VueUtils.RouterBeforeEach);

export default router
